require('./bootstrap');
require('jstree');///dist/jstree
require('jquery-mask-plugin');
window.moment = require('moment');
require('daterangepicker');
window.Highcharts = require('highcharts');
import 'datatables.net-bs5';
import 'datatables.net-buttons-bs5';
window.XLSX = require('xlsx');
window.Swal = require('sweetalert2');

moment.locale('ru');

(function () {
    'use strict';
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.forEach(function (tooltipTriggerEl) {
        new bootstrap.Tooltip(tooltipTriggerEl);
    });
})();

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

window.preloader = function(action) {
    if (action === "show") {
        $('#preloader').css('display','block');
    } else {
        $('#preloader').css('display','none');
    }
};

window.ajaxFormErrors = function(errors,elem_class) {
    $("."+elem_class).text("");
    for (let field in errors) {
        if (errors[field].length > 0) {
            $("."+elem_class+"[for='"+field+"']").text(errors[field]);
        }
    }
};

let alertToastEl = document.getElementById('alertToast');
window.alertToastObj = bootstrap.Toast.getOrCreateInstance(alertToastEl);

window.alertToast = function(type,message) {
    switch (type) {
        case 'danger':
            $(alertToastEl).removeClass("bg-success").addClass("bg-danger");
            break;
        case 'success':
            $(alertToastEl).removeClass("bg-danger").addClass("bg-success");
            break;
    }
    document.getElementById('alert-toast-body').innerHTML = message;
    window.alertToastObj.show();
};

$("#language-select").click(function(){
    $.ajax({
        url : '/lang',
        type : 'post',
        datatype : 'json',
        data:{
            lang:this.getAttribute('_lang')
        }
    }).done(function(json){
        if (json.status) {
            window.location.reload();
        }
    }).fail(function(jqXHR,ajaxOptions,thrownError){
        $("#alert-toast-body").empty().append("Ошибка смены языка!");
        window.alertToast.show();
    });
});

window.highcharts_lang =  {
    months: [
        'Январь', 'Февраль', 'Март', 'Апрель',
        'Май', 'Июнь', 'Июль', 'Август',
        'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
    ],
    weekdays: [
        'Воскресенье', 'Понедельник', 'Вторник', 'Среда',
        'Четверг', 'Пятница', 'Суббота'
    ],
    shortMonths: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек']
};

window.Highcharts.setOptions({
    lang:window.highcharts_lang
});

window.showSection = function (evt, cityName){
    // Declare all variables
    var i, tabcontent, tablinks;

    // Get all elements with class="tabcontent" and hide them
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    // Get all elements with class="tablinks" and remove the class "active"
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }

    // Show the current tab, and add an "active" class to the link that opened the tab
    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";
};

window.datatables_lang = {
    "processing": "Подождите...",
    "search": "Поиск:",
    "lengthMenu": "Показать _MENU_ записей",
    "info": "Записи с _START_ до _END_ из _TOTAL_ записей",
    "infoEmpty": "Записи с 0 до 0 из 0 записей",
    "infoFiltered": "(отфильтровано из _MAX_ записей)",
    "loadingRecords": "Загрузка записей...",
    "zeroRecords": "Записи отсутствуют.",
    "emptyTable": "В таблице отсутствуют данные",
    "paginate": {
        "first": "Первая",
        "previous": "Предыдущая",
        "next": "Следующая",
        "last": "Последняя"
    },
    "aria": {
        "sortAscending": ": активировать для сортировки столбца по возрастанию",
        "sortDescending": ": активировать для сортировки столбца по убыванию"
    },
    "select": {
        "rows": {
            "_": "Выбрано записей: %d",
            "1": "Выбрана одна запись"
        },
        "cells": {
            "_": "Выбрано %d ячеек",
            "1": "Выбрана 1 ячейка "
        },
        "columns": {
            "1": "Выбран 1 столбец ",
            "_": "Выбрано %d столбцов "
        }
    },
    "searchBuilder": {
        "conditions": {
            "string": {
                "startsWith": "Начинается с",
                "contains": "Содержит",
                "empty": "Пусто",
                "endsWith": "Заканчивается на",
                "equals": "Равно",
                "not": "Не",
                "notEmpty": "Не пусто",
                "notContains": "Не содержит",
                "notStartsWith": "Не начинается на",
                "notEndsWith": "Не заканчивается на"
            },
            "date": {
                "after": "После",
                "before": "До",
                "between": "Между",
                "empty": "Пусто",
                "equals": "Равно",
                "not": "Не",
                "notBetween": "Не между",
                "notEmpty": "Не пусто"
            },
            "number": {
                "empty": "Пусто",
                "equals": "Равно",
                "gt": "Больше чем",
                "gte": "Больше, чем равно",
                "lt": "Меньше чем",
                "lte": "Меньше, чем равно",
                "not": "Не",
                "notEmpty": "Не пусто",
                "between": "Между",
                "notBetween": "Не между ними"
            },
            "array": {
                "equals": "Равно",
                "empty": "Пусто",
                "contains": "Содержит",
                "not": "Не равно",
                "notEmpty": "Не пусто",
                "without": "Без"
            }
        },
        "data": "Данные",
        "deleteTitle": "Удалить условие фильтрации",
        "logicAnd": "И",
        "logicOr": "Или",
        "title": {
            "0": "Конструктор поиска",
            "_": "Конструктор поиска (%d)"
        },
        "value": "Значение",
        "add": "Добавить условие",
        "button": {
            "0": "Конструктор поиска",
            "_": "Конструктор поиска (%d)"
        },
        "clearAll": "Очистить всё",
        "condition": "Условие",
        "leftTitle": "Превосходные критерии",
        "rightTitle": "Критерии отступа"
    },
    "searchPanes": {
        "clearMessage": "Очистить всё",
        "collapse": {
            "0": "Панели поиска",
            "_": "Панели поиска (%d)"
        },
        "count": "{total}",
        "countFiltered": "{shown} ({total})",
        "emptyPanes": "Нет панелей поиска",
        "loadMessage": "Загрузка панелей поиска",
        "title": "Фильтры активны - %d",
        "showMessage": "Показать все",
        "collapseMessage": "Скрыть все"
    },
    "buttons": {
        "pdf": "PDF",
        "print": "Печать",
        "collection": "Коллекция <span class=\"ui-button-icon-primary ui-icon ui-icon-triangle-1-s\"><\/span>",
        "colvis": "Видимость столбцов",
        "colvisRestore": "Восстановить видимость",
        "copy": "Копировать",
        "copyKeys": "Нажмите ctrl or u2318 + C, чтобы скопировать данные таблицы в буфер обмена.  Для отмены, щелкните по сообщению или нажмите escape.",
        "copyTitle": "Скопировать в буфер обмена",
        "csv": "CSV",
        "excel": "Excel",
        "pageLength": {
            "-1": "Показать все строки",
            "_": "Показать %d строк",
            "1": "Показать 1 строку"
        },
        "removeState": "Удалить",
        "renameState": "Переименовать",
        "copySuccess": {
            "1": "Строка скопирована в буфер обмена",
            "_": "Скопировано %d строк в буфер обмена"
        },
        "createState": "Создать состояние",
        "removeAllStates": "Удалить все состояния",
        "savedStates": "Сохраненные состояния",
        "stateRestore": "Состояние %d",
        "updateState": "Обновить"
    },
    "decimal": ".",
    "infoThousands": ",",
    "autoFill": {
        "cancel": "Отменить",
        "fill": "Заполнить все ячейки <i>%d<i><\/i><\/i>",
        "fillHorizontal": "Заполнить ячейки по горизонтали",
        "fillVertical": "Заполнить ячейки по вертикали",
        "info": "Информация"
    },
    "datetime": {
        "previous": "Предыдущий",
        "next": "Следующий",
        "hours": "Часы",
        "minutes": "Минуты",
        "seconds": "Секунды",
        "unknown": "Неизвестный",
        "amPm": [
            "AM",
            "PM"
        ],
        "months": {
            "0": "Январь",
            "1": "Февраль",
            "10": "Ноябрь",
            "11": "Декабрь",
            "2": "Март",
            "3": "Апрель",
            "4": "Май",
            "5": "Июнь",
            "6": "Июль",
            "7": "Август",
            "8": "Сентябрь",
            "9": "Октябрь"
        },
        "weekdays": [
            "Вс",
            "Пн",
            "Вт",
            "Ср",
            "Чт",
            "Пт",
            "Сб"
        ]
    },
    "editor": {
        "close": "Закрыть",
        "create": {
            "button": "Новый",
            "title": "Создать новую запись",
            "submit": "Создать"
        },
        "edit": {
            "button": "Изменить",
            "title": "Изменить запись",
            "submit": "Изменить"
        },
        "remove": {
            "button": "Удалить",
            "title": "Удалить",
            "submit": "Удалить",
            "confirm": {
                "_": "Вы точно хотите удалить %d строк?",
                "1": "Вы точно хотите удалить 1 строку?"
            }
        },
        "multi": {
            "restore": "Отменить изменения",
            "title": "Несколько значений",
            "noMulti": "Это поле должно редактироватся отдельно, а не как часть групы",
            "info": "Выбранные элементы содержат разные значения для этого входа.  Чтобы отредактировать и установить для всех элементов этого ввода одинаковое значение, нажмите или коснитесь здесь, в противном случае они сохранят свои индивидуальные значения."
        },
        "error": {
            "system": "Возникла системная ошибка (<a target=\"\\\" rel=\"nofollow\" href=\"\\\">Подробнее<\/a>)."
        }
    },
    "searchPlaceholder": "Что ищете?",
    "stateRestore": {
        "creationModal": {
            "button": "Создать",
            "search": "Поиск",
            "columns": {
                "search": "Поиск по столбцам",
                "visible": "Видимость столбцов"
            },
            "name": "Имя:",
            "order": "Сортировка",
            "paging": "Страницы",
            "scroller": "Позиция прокрутки",
            "searchBuilder": "Редактор поиска",
            "select": "Выделение",
            "title": "Создать новое состояние",
            "toggleLabel": "Включает:"
        },
        "removeJoiner": "и",
        "removeSubmit": "Удалить",
        "renameButton": "Переименовать",
        "duplicateError": "Состояние с таким именем уже существует.",
        "emptyError": "Имя не может быть пустым.",
        "emptyStates": "Нет сохраненных состояний",
        "removeConfirm": "Вы уверены, что хотите удалить %s?",
        "removeError": "Не удалось удалить состояние.",
        "removeTitle": "Удалить состояние",
        "renameLabel": "Новое имя для %s:",
        "renameTitle": "Переименовать состояние"
    },
    "thousands": " "
};

//window.websocket = new WebSocket('wss://127.0.0.1:13579/');
//window.callback = null;
//window.callbackArgs = null;
//
//window.websocket.onopen = function (event) {
//    console.log("Connection opened");
//};
//
//window.websocket.onclose = function (event) {
//    if (event.wasClean) {
//        console.log('connection has been closed');
//    } else {
//        console.log('Connection error, reconnect');
//        window.websocket = new WebSocket('wss://127.0.0.1:13579/');
//    }
//    console.log('Code: ' + event.code + ' Reason: ' + event.reason);
//};
//
//window.websocket.onmessage = function (event) {
//    var result = JSON.parse(event.data);
//
//    if (result !== null) {
//        var rw = {
//            code: result['code'],
//            message: result['message'],
//            responseObject: result['responseObject'],
//            getResult: function () {
//                    return this.result;
//            },
//            getMessage: function () {
//                    return this.message;
//            },
//            getResponseObject: function () {
//                    return this.responseObject;
//            },
//            getCode: function () {
//                    return this.code;
//            }
//        };
//        if (window.callback !== null) {
//            window[callback](rw, window.callbackArgs);
//        }
//    }
//};
//
//window.getKeyInfo = function (storageName, callBack, params = false) {
//    var getKeyInfo = {
//        "module": "kz.gov.pki.knca.commonUtils",
//        "method": "getKeyInfo",
//        "args": [storageName]
//    };
//    callback = callBack;
//    console.log('getKeyInfo:');
//    console.log(params);
//    callbackArgs = params;
//    window.websocket.send(JSON.stringify(getKeyInfo));
//};
//
//window.signXml = function(storageName, keyType, xmlToSign, callBack, params = false) {
//    var signXml = {
//        "module": "kz.gov.pki.knca.commonUtils",
//        "method": "signXml",
//        "args": [storageName, keyType, xmlToSign, "", ""]
//    };
//    callback = callBack;
//    callbackArgs = params;
//    window.websocket.send(JSON.stringify(signXml));
//};
//
//window.getKeyInfoCall = function (params = false) {
//    window.preloader("show");
//    var selectedStorage = $('#storageSelect').val();
//    getKeyInfo(selectedStorage, "getKeyInfoBack", params);
//};
//
//window.getKeyInfoBack = function (result, params = false) {
//    window.preloader("hide");
//    if (result['code'] === "500") {
//        alert(result['message']);
//    } else if (result['code'] === "200") {
//        var res = result['responseObject'];
//        var pem = res['pem'];
//        $("#pem").val(pem);
//        console.log('getKeyInfoBack:');
//        console.log(params);
//        window[params.callback](params.data);
//    }
//};
//
//window.signXmlCall = function () {
//    window.preloader("show");
//    var xmlToSign = $("#xmlToSign").val();
//    var selectedStorage = $('#storageSelect').val();
//    signXml(selectedStorage, "SIGNATURE", xmlToSign, "signXmlBack");
//};
//
//window.signXmlBack = function (result) {
//    window.preloader("hide");
//    if (result['code'] === "500") {
//        alert(result['message']);
//    } else if (result['code'] === "200") {
//        var res = result['responseObject'];
//        $("#signedXml").val(res);
//    }
//};
//
